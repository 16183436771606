import { Expose } from 'class-transformer';
import Company from '@/models/Company.js'

class AppointmentRequest {
    @Expose({ name: 'appointmentRequestid' })
    appointmentRequestid;

    @Expose({ name: 'type' })
    type;

    @Expose({ name: 'dateSuggestion' })
    dateSuggestion;

    @Expose({ name: 'salutation' })
    salutation;

    @Expose({ name: 'firstname' })
    firstname;

    @Expose({ name: 'lastname' })
    lastname;

    @Expose({ name: 'email' })
    email;

    @Expose({ name: 'phone' })
    phone;

    @Expose({ name: 'company' })
    company = new Company();

    @Expose({ name: 'advertisement' })
    advertisement;

    @Expose({ name: 'createdOn' })
    createdOn;

    getSalutation(){
        let salutationString = ""

        if (this.salutation == "Frau") {
            salutationString = "Sehr geehrte Frau"
        }
        else if (this.salutation == "Herrn") {
            salutationString = "Sehr geehrter Herr"
        }
        else {
            salutationString = "Hallo"
        }

        return salutationString + " " + this.firstname + " " + this.lastname + ",";
    } 
}

export default AppointmentRequest;