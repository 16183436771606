<template>
  <!-- <Navbar /> -->
  <router-view />
  <Footer class="d-print-none" />
</template>

<script>
// import Navbar from '@/components/Shared/NavBar.vue'
import Footer from '@/components/Shared/FooterBar.vue'

export default {
  name: 'App',
  components: {
    // Navbar,
    Footer
  }
}
</script>

<style>
#app {
  overflow: hidden;
}
</style>
