<template>
    <!-- FORM -->
    <section class="pt-8 pt-md-11 pb-8 pb-md-14">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 text-center">

                    <!-- Heading -->
                    <h2 class="fw-bold">
                        Keine Antwort auf deine Frage?
                    </h2>

                    <!-- Text -->
                    <p class="fs-lg text-body-secondary mb-7 mb-md-9">
                        Kontaktiere uns und wir werden so schnell wie möglich eine Lösung für dein Problem finden.
                    </p>

                </div>
            </div> <!-- / .row -->
            <div class="row mb-7 mb-md-9">
                <div class="col-12 col-md-6 text-center">

                    <!-- Heading -->
                    <h6 class="text-uppercase text-gray-700 mb-1">
                        Schreib uns
                    </h6>

                    <!-- Link -->
                    <div class="mb-5 mb-md-0">
                        <a href="https://wa.me/4917665883595" class="h4 text-success">
                            Starte einen Chat!
                        </a>
                    </div>

                </div>
                <!-- <div class="col-12 col-md-4 text-center border-start-md border-gray-300">

					<h6 class="text-uppercase text-gray-700 mb-1">
						Call anytime
					</h6>

					<div class="mb-5 mb-md-0">
						<a href="#!" class="h4 text-primary">
							(555) 123-4567
						</a>
					</div>

				</div> -->
                <div class="col-12 col-md-6 text-center border-start-md border-gray-300">

                    <!-- Heading -->
                    <h6 class="text-uppercase text-gray-700 mb-1">
                        Email uns
                    </h6>

                    <!-- Link -->
                    <a href= "mailto: support@alldaycare.de" class="h4 text-success">
                        support@alldaycare.de
                    </a>

                </div>
            </div> <!-- / .row -->
            <div ref="targetRef" class="card card-bleed shadow-light-lg mb-6">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <!-- Form -->
                            <form @submit.prevent="onHelpRequestSubmit" novalidate>
                                <div class="row">
                                    <div class="col-12 col-md-6 mt-1">
                                        <div class="form-group mb-5">
                                            <v-text-field v-model="helpRequest.name" label="Name" hide-details="auto" placeholder="Vor- und Nachname"></v-text-field>
                                            <div v-for="error of v$.helpRequest.name.$errors"
                                                :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 mt-1">
                                        <div class="form-group mb-5">
                                            <v-text-field v-model="helpRequest.email" label="Email" hide-details="auto" placeholder="hallo@domain.de"></v-text-field>
                                            <div v-for="error of v$.helpRequest.email.$errors"
                                                :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-1">
                                        <div class="form-group mb-5 mb-md-7">
                                            <v-textarea v-model="helpRequest.request" label="Wie können wir dir helfen?" placeholder="Erzähl uns, wie wir dir helfen können." hide-details="auto"></v-textarea>
                                            <div v-for="error of v$.helpRequest.request.$errors"
                                                :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div> <!-- / .row -->

                                <div class="row justify-content-center">
                                    <div class="col-auto">

                                        <button class="btn btn-primary lift shadow-lg" type="submit">
                                            <span v-if="!loading">Anfrage senden</span>
                                            <div v-else>
                                                <span class="spinner-grow spinner-grow-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Ihre Anfrage wird überprüft...
                                            </div>
                                        </button>

                                    </div>
                                </div> <!-- / .row -->
                            </form>
                        </div>
                    </div> <!-- / .row -->
                </div>
            </div>
        </div> <!-- / .container -->
    </section>

    <ConfirmationView :helpRequest="helpRequest" ref="confirmationModal" />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from '@vuelidate/validators'

import HelpRequest from '@/models/HelpRequest.js'
import ConfirmationView from '@/components/HelpCenterSection/ConfirmationView.vue';
import { sendHelpRequest } from '@/plugins/helpCenterService.js'

export default {
    components: {
        ConfirmationView
    },
    data() {
        return {
            helpRequest: new HelpRequest(),
            loading: false,
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    methods: {
        async onHelpRequestSubmit() {
            if (this.loading)
                return;

            this.loading = true;

            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.loading = false;
                this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
                return;
            }

            const successful = await sendHelpRequest(this.helpRequest);
            if (successful){
                this.$refs.confirmationModal.openModal();
            }

            this.loading = false;
        },
    },
    validations() {
        return {
            helpRequest: {
                name: { required: helpers.withMessage('Der Name ist erforderlich', required) },
                email: {
                    required: helpers.withMessage('Die Email ist erforderlich', required),
                    email: helpers.withMessage('Die Email ist nicht gültig', email)
                },
                request: { required: helpers.withMessage('Eine Anfrage ist erforderlich', required) },
            }
        }
    }
}
</script>