<template>
    <!-- SHAPE -->
    <div class="position-relative d-print-none">
        <div class="shape shape-bottom shape-fluid-x text-dark" >
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
            </svg>
        </div>
    </div>

    <!-- FOOTER -->
    <footer class="py-8 pt-md-11 pb-md-9 bg-dark d-print-none">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4">

                    <!-- Brand -->
                    <a class="navbar-brand" href="/">
                        <img src="@/assets/img/logos/AllDayCareText.svg" alt="..." class="footer-brand img-fluid mb-2" style="max-width: 50%;">
                    </a>
                    

                    <!-- Text -->
                    <p class="text-white mb-2">
                        Innovation für Ihren täglichen Erfolg.
                    </p>

                    <!-- Social -->
                    <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
                        <li class="list-inline-item list-social-item me-3">
                            <a href="https://wa.me/4917665883595" target="_" class="text-decoration-none">
                                <img src="@/assets/img/icons/social/whatsapp-50.svg" class="list-social-icon footer-brand" alt="...">
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item me-3">
                            <a href="https://www.facebook.com/profile.php?id=61555930289213" target="_" class="text-decoration-none">
                                <img src="@/assets/img/icons/social/facebook.svg" class="list-social-icon footer-brand" alt="...">
                            </a>
                        </li>
                        <!-- <li class="list-inline-item list-social-item me-3">
                            <a href="#!" target="_" class="text-decoration-none">
                                <img src="@/assets/img/icons/social/instagram.svg" class="list-social-icon footer-brand" alt="...">
                            </a>
                        </li> -->
                        <li class="list-inline-item list-social-item me-3">
                            <a href="https://www.linkedin.com/company/alldaycare/" target="_" class="text-decoration-none">
                                <img src="@/assets/img/icons/social/linkedin.svg" class="list-social-icon footer-brand" alt="...">
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Products
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-body-secondary mb-6 mb-md-8 mb-lg-0">
                        <li class="mb-3">
                            <a href="/functions" class="text-reset">
                                Funktionen
                            </a>
                        </li>
                        <li class="mb-3">
                            <a href="/pricing" class="text-reset">
                                Preise
                            </a>
                        </li>
                        <li class="mb-3">
                            <a href="/help" class="text-reset">
                                Hilfe
                            </a>
                        </li>
                        <li class="mb-3">
                            <a href="/contact" class="text-reset">
                                Kontakt
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Services
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-body-secondary mb-6 mb-md-8 mb-lg-0">
                        <li class="mb-3">
                            <a href="#!" class="text-reset">
                                Dokumentation
                            </a>
                        </li>
                        <li class="mb-3">
                            <a href="#!" class="text-reset">
                                Changelog
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Legal
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-body-secondary mb-0">
                        <li class="mb-3">
                            <a href="imprint" class="text-reset">
                                Impressum
                            </a>
                        </li>
                        <li class="mb-3">
                            <a href="terms" class="text-reset">
                                AGBs
                            </a>
                        </li>
                        <li>
                            <a href="#!" class="text-reset">
                                Datenschutz
                            </a>
                        </li>
                    </ul>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </footer>
</template>