<template>
    <div class="card shadow-light-lg">
        <div class="card-body">

            <!-- Heading -->
            <h4>
                Gibt es Fragen?
            </h4>

            <!-- Text -->
            <p class="fs-sm text-gray-800 mb-5">
                Falls Sie sich nicht ganz sicher sind, was genau Sie suchen, oder eine Klarstellung
                benötigen, sprechen wir gerne mit Ihnen und klären alles für Sie. Jederzeit!
            </p>

            <!-- Heading -->
            <h6 class="fw-bold text-uppercase text-gray-700 mb-2">
                Telefon (WhatsApp)
            </h6>

            <!-- Text -->
            <p class="fs-sm mb-5">
                <a href="tel:+4917665883595" class="text-reset">(+49) 17665883595</a>
            </p>

            <!-- Heading -->
            <h6 class="fw-bold text-uppercase text-gray-700 mb-2">
                Email
            </h6>

            <!-- Text -->
            <p class="fs-sm mb-0">
                <a href="mailto:support@alldaycare.de" class="text-reset">support@alldaycare.de</a>
            </p>

        </div>
    </div>
</template>