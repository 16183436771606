<template>
    <!-- Modal -->
    <div class="modal fade" id="confirmationModal" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title">Terminbestätigung</h1>
                </div>
                <div class="modal-body" v-if="appointmentRequest">
                    <p>{{ appointmentRequest.getSalutation() }}</p>

                    <p v-if="appointmentRequest.dateSuggestion">vielen Dank für Ihre Terminbuchung! Wir freuen uns auf
                        den Termin am
                        {{ appointmentRequest.dateSuggestion.getDateString() }} zwischen
                        {{ appointmentRequest.dateSuggestion.getTimeString() }} Uhr.<br>Wir werden Ihnen nochmals
                        eine genau Uhrzeit bestätigen.</p>
                    <p v-else>
                        vielen Dank für Ihre Anfrage! Wir werden uns schnellstmöglich bei Ihnen über
                        <strong>WhatsApp</strong> unter der Nummer <strong>{{ appointmentRequest.phone }}</strong>
                        melden.
                    </p>

                    <p>Eine Bestätigung Ihrer Anfrage wurde an Ihre E-Mail-Adresse
                        <strong>{{ appointmentRequest.email }}</strong>
                        gesendet. Bitte
                        überprüfen Sie auch Ihren Spam-Ordner, falls die E-Mail nicht in Ihrem Posteingang angezeigt
                        wird.
                    </p>
                    <p>Bei Fragen oder Änderungswünschen können Sie uns jederzeit kontaktieren.</p>
                    <p>Wir freuen uns auf ein persönliches Gespräch!</p>
                    <p>Mit freundlichen Grüßen,<br>AllDayCare</p>
                </div>
                <div class="modal-footer">
                    <a type="button" class="btn btn-primary" href="/">Zurück zur
                        Startseite</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bootstrap from 'bootstrap';

export default {
    data() {
        return {
            appointmentRequest: null
        }
    },
    methods: {
        openModal(appointmentRequest) {
            if (this.appointmentRequest !== appointmentRequest) {
                this.appointmentRequest = appointmentRequest;
            }
            const modal = new bootstrap.Modal(document.getElementById('confirmationModal'));
            modal.show();
        },
    }
}
</script>