<template>
    <Navbar :isDark="true" />

    <section class="mt-n11 pt-12 pb-4 pt-md-14 pb-md-6 bg-black bg-pattern-2" v-if="dateSuggestions">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 text-center">

                    <!-- Headin -->
                    <h1 class="display-2 fw-bold text-white" data-aos="fade-up" data-aos-delay="50">
                        Wir legen Wert auf persönlichen Kontakt.<br>
                    </h1>

                    <!-- Text -->
                    <p class="lead text-gray-500 mb-6 mx-auto" data-aos="fade-up" data-aos-delay="100"
                        style="max-width: 500px;">
                        Buchen Sie einen unverbindlichen Termin mit Ihrem persönlichen Ansprechpartner.
                    </p>
                </div>
            </div> <!-- / .row -->
            <div class="row mb-6 mb-md-12">
                <div class="col-12" data-aos="fade-up" data-aos-delay="200">
                    <!-- Form -->
                    <form @submit.prevent="onAppointmentSubmit" novalidate>

                        <!-- Card -->
                        <div class="card card-bleed shadow-light-lg mb-6">
                            <div class="card-body">
                                <div class="row">
                                    <div ref="targetRef" class="col-12 d-grid mb-8 mb-md-6">
                                        <label class="text-h6" for="salutation">Sie wünschen ein</label>
                                        <v-btn-toggle v-model="meeting" divided variant="tonal" class="d-flex" success>
                                            <v-btn class="flex-grow-1" :value="false"
                                                text="Chat (keine Wartezeiten)"></v-btn>
                                            <v-btn class="flex-grow-1" :value="true" text="Meeting"></v-btn>
                                        </v-btn-toggle>
                                        <div class="mt-2" v-if="!meeting">
                                            <small>Bitte stellen Sie sicher, dass Sie für eine Chatanfrage
                                                <strong>WhatsApp</strong> verwenden.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" v-if="meeting & suggestionDates.length > 0">
                                    <div class="col-12 col-md-6 mb-4 mb-md-8">
                                        <v-card class="mx-auto" variant="outlined">
                                            <v-date-picker :model-value="date" @update:model-value="onSelectDate"
                                                class="mt-3" width="100%"
                                                :allowed-dates="suggestionDates"></v-date-picker>
                                        </v-card>
                                    </div>

                                    <div class="col-12 col-md-6 mb-8">
                                        <v-card class="mx-auto pt-3 px-2 h-100" variant="outlined">
                                            <label class="v-picker-title" for="time">Uhrzeit auswählen</label>
                                            <v-list :items="suggestionTimes" @update:selected="onSelectTime" lines="two"
                                                color="success"></v-list>
                                        </v-card>

                                        <div v-for="error of v$.appointment.dateSuggestion.$errors" :key="error.$uid">
                                            <small class="error-msg">{{ error.$message }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="meeting & suggestionDates.length == 0">
                                    <div class="alert alert-secondary" role="alert">
                                        <p>Leider sind derzeit keine Termine verfügbar.</p>
                                        <hr>
                                        <p class="mb-0">Wir werden Ihre Anfrage in einen Chat umwandeln. Ihr
                                            persönlicher Ansprechpartner wird sich mit Ihnen in Verbindung setzen, um
                                            einen Termin zu vereinbaren.</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">

                                        <!-- Salutation -->
                                        <div class="form-group">
                                            <v-combobox v-model="appointment.salutation" label="Wählen Sie Ihre Anrede"
                                                :items="['Frau', 'Herrn', 'Keine Angabe']"
                                                hide-details="auto"></v-combobox>
                                            <div v-for="error of v$.appointment.salutation.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Firstname -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.firstname" label="Vorname"
                                                hide-details="auto"></v-text-field>
                                            <div v-for="error of v$.appointment.firstname.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Lastname -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.lastname" label="Nachname"
                                                hide-details="auto"></v-text-field>
                                            <div v-for="error of v$.appointment.lastname.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Email -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.email" label="Email"
                                                hide-details="auto"></v-text-field>
                                            <div v-for="error of v$.appointment.email.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Phone -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.phone" label="Telefonnummer"
                                                hint="Mit der wir Sie bei WhatsApp erreichen können"
                                                hide-details="auto"></v-text-field>
                                            <div v-for="error of v$.appointment.phone.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <!-- Card -->
                        <div class="card card-bleed shadow-light-lg mb-6">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Company name -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.company.name" label="Unternehmensname"
                                                hide-details="auto"></v-text-field>
                                            <div v-for="error of v$.appointment.company.name.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Company size -->
                                        <div class="form-group">
                                            <v-select v-model="appointment.company.size" label="Wie viele Klienten unterstützen
                                                Sie?" hide-details="auto"
                                                :items="['weniger als 10 Klienten', 'mehr als 10 Klienten', 'mehr als 50 Klienten', 'mehr als 100 Klienten', 'mehr als 300 Klienten']"></v-select>
                                            <div v-for="error of v$.appointment.company.size.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- Company city -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.company.city"
                                                label="Unternehmensstandort" hide-details="auto"
                                                hint="Die Stadt oder der Landkreis in dem Sie unterstützen"></v-text-field>
                                            <div v-for="error of v$.appointment.company.city.$errors" :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-1">

                                        <!-- State -->
                                        <div class="form-group">
                                            <v-autocomplete v-model="appointment.company.state" label="Bundesland"
                                                hide-details="auto"
                                                :items="['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen', 'mehrere Bundesländer']"></v-autocomplete>
                                            <div v-for="error of v$.appointment.company.state.$errors"
                                                :key="error.$uid">
                                                <small class="error-msg">{{ error.$message }}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">

                                        <!-- Website -->
                                        <div class="form-group">
                                            <v-text-field v-model="appointment.company.websiteUrl"
                                                label="Website (optional)" hide-details="auto"></v-text-field>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">

                                        <!-- State -->
                                        <div class="form-group">
                                            <v-select v-model="appointment.advertisement" label="Wie sind Sie auf uns aufmerksam
                                                geworden?" hide-details="auto"
                                                :items="['über einen Partner', 'Facebook', 'Google', 'Instagram', 'LinkedIn']"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 d-grid gap-2 mx-auto mt-3">
                                        <!-- Button -->
                                        <button class="btn btn-primary" type="submit">
                                            <span v-if="!loading">Termin bestätigen</span>
                                            <div v-else>
                                                <span class="spinner-grow spinner-grow-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Ihre Buchung wird überprüft...
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>

    <v-snackbar v-model="snackbar" vertical :timeout="-1" color="dark">
        <div class="text-subtitle-1 pb-2">Kein passender Termin? Kein Problem!</div>

        <p>
            Es ist kein passender Termin für Sie vorhanden? Versuche Sie die Chatfunktion, wir melden uns
            bei Ihnen und finden den richtigen Zeitpunkt!
        </p>

        <template v-slot:actions>
            <v-btn color="success" variant="text" @click="snackbar = false">
                Schließen
            </v-btn>
        </template>
    </v-snackbar>

    <ConfirmationView :appointmentRequest="appointment" ref="confirmationModal" />
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'
import ConfirmationView from '@/components/ContactSection/ConfirmationView.vue';
import AppointmentRequest from '@/models/AppointmentRequest.js'

import { useVuelidate } from "@vuelidate/core";
import { required, email, requiredIf, helpers } from '@vuelidate/validators'
import { getDateSuggestions, sendAppointmentRequest } from '@/plugins/meetingService.js'

export default {
    components: {
        Navbar,
        ConfirmationView
    },
    data() {
        return {
            date: null,
            appointment: new AppointmentRequest(),
            dateSuggestions: [],
            suggestionDates: [],
            suggestionTimes: [],
            minDate: null,
            selectOptionsSalutation: [
                { id: "1", text: "Frau" },
                { id: "2", text: "Herr" },
                { id: "3", text: "Keine Angabe" },
            ],
            selectOptionsCompanySize: [
                { id: "1", text: "Weniger als 10 Klienten" },
                { id: "2", text: "Mehr als 10 Klienten" },
                { id: "3", text: "Mehr als 50 Klienten" },
                { id: "4", text: "Mehr als 100 Klienten" },
                { id: "5", text: "Mehr als 300 Klienten" }
            ],
            meeting: false,
            time: null,
            dateTimeClickCount: 0,
            snackbar: false,
            loading: false,
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        this.loadDataSuggestions();
    },
    methods: {
        async loadDataSuggestions() {
            this.dateSuggestions = await getDateSuggestions();

            console.debug(this.dateSuggestions);
            if (this.dateSuggestions) {
                const datesSet = new Set();
                this.dateSuggestions.forEach(suggestion => {
                    const suggestionDate = suggestion.getDate();
                    if (!this.date || this.date > suggestionDate) {
                        this.date = suggestionDate;
                    }
                    datesSet.add(suggestionDate);
                });

                this.onSelectDate(this.date);
                this.suggestionDates = Array.from(datesSet);
            }
        },
        onSelectDate(selectedDate) {
            if (this.date !== selectedDate) {
                this.date = selectedDate;
            }

            this.suggestionTimes = this.dateSuggestions
                .filter(suggestion => suggestion.getDate().toISOString() == selectedDate.toISOString())
                .map(suggestion => ({
                    title: suggestion.getTimeString(),
                    value: suggestion
                }));
        },
        onSelectTime(item) {
            this.appointment.dateSuggestion = item[0];
        },
        addDay(date, days) {
            const result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        async onAppointmentSubmit() {
            try {
                if (this.loading)
                    return;

                this.loading = true;

                if (this.meeting && this.suggestionDates.length == 0) {
                    this.meeting = false;
                }

                if (this.meeting) {
                    this.appointment.type = "Meeting"
                }
                else {
                    this.appointment.type = "Chat"
                }

                const isFormCorrect = await this.v$.$validate()
                if (!isFormCorrect) {
                    this.loading = false;
                    this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
                    return
                }

                this.$refs.confirmationModal.openModal(this.appointment);
                await sendAppointmentRequest(this.appointment);
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false;
            }
        },
        onSalutationSelect(result) {
            if (result == 1) {
                this.appointment.salutation = "Frau";

                this.firstnamePlaceholder = "Erika";
                this.lastnamePlaceholder = "Musterfrau";
            }
            else if (result == 2) {
                this.appointment.salutation = "Herrn";

                this.firstnamePlaceholder = "Max";
                this.lastnamePlaceholder = "Mustermann";
            }
            else {
                this.appointment.salutation = "Keine Angabe";

                this.firstnamePlaceholder = "Vorname";
                this.lastnamePlaceholder = "Nachname";
            }
        },
        onClickDateTime() {
            this.dateTimeClickCount++;
            console.log("User clicked:", this.dateTimeClickCount)

            if (this.dateTimeClickCount > 25)
                this.snackbar = true;
        },
    },
    validations() {
        return {
            appointment: {
                salutation: { required: helpers.withMessage('Die Anrede ist erforderlich', required) },
                firstname: { required: helpers.withMessage('Der Vorname ist erforderlich', required) },
                lastname: { required: helpers.withMessage('Der Nachname ist erforderlich', required) },
                email: {
                    required: helpers.withMessage('Die Email ist erforderlich', required),
                    email: helpers.withMessage('Die Email ist nicht gültig', email)
                },
                company: {
                    name: { required: helpers.withMessage('Der Unternehmensname ist erforderlich', required) },
                    city: { required: helpers.withMessage('Der Unternehmensstandort ist erforderlich', required) },
                    state: { required: helpers.withMessage('Das Bundesland ist erforderlich', required) },
                    size: { required: helpers.withMessage('Die Anzahl unterstützter Klienten ist erforderlich', required) },
                },
                phone: {
                    requiredIf: helpers.withMessage('Die Telefonnummer ist für einen Chat notwendig', requiredIf(() => !this.meeting))
                },
                dateSuggestion: {
                    requiredIf: helpers.withMessage('Bitte wähle einen Termin', requiredIf(() => this.meeting))
                },
            }
        }
    }
}
</script>

<style lang="css">
.error-msg {
    color: #FF6666;
}
</style>