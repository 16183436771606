import DateSuggestion from "@/models/DateSuggestion";
import axios from 'axios'
import { plainToClass } from 'class-transformer';

const apiUri = process.env.VUE_APP_API_URL;

export async function getDateSuggestions() {
    try {
        const response = await axios.get(`${apiUri}/api/Meeting/dateSuggestions`);
        const dateSuggestions = plainToClass(DateSuggestion, response.data);
        return dateSuggestions
    } catch (error) {
        console.error('There was an error!', error);
        return [];
    }
}

export async function sendAppointmentRequest(appointmentRequest) {
    try {
        const requestBody = JSON.stringify(appointmentRequest);

        const response = await axios.put(`${apiUri}/api/Meeting/appointmentRequest`, requestBody);
        console.log('Response:', response.data);
    } catch (error) {
        console.error('There was an error!', error);
    }
}