import { createApp } from 'vue'
import { router } from './router/index'

import App from './App.vue'

import { firebaseApp } from './plugins/firebase'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { de } from 'vuetify/locale'

const vuetify = createVuetify({
  locale:{
    locale: 'de',
    fallback: 'en',
    messages: { de },
  },
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
})

import '@/assets/css/libs.bundle.css'
import '@/assets/css/theme.bundle.css'

import '@/assets/js/theme.bundle'
import '@/assets/js/vendor.bundle'

// Smooth Scroll
import Vue3SmoothScroll from 'vue3-smooth-scroll'

const app = createApp(App)


app.use(firebaseApp)
app.use(router)
app.use(vuetify)

app.use(Vue3SmoothScroll, {
  updateHistory: false,
})

app.mount('#app')
