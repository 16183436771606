import { Expose } from 'class-transformer';

class HelpCategory {
    @Expose({ name: 'helpCategoryId' })
    helpCategoryId;

    @Expose({ name: 'name' })
    name;

    @Expose({ name: 'description' })
    description;

    @Expose({ name: 'icon' })
    icon;

    @Expose({ name: 'color' })
    color;

    get topicsCount() {
        if (this.topics)
            return this.topics.length;
        return 0;
    }
}

export default HelpCategory;