<template>
    <NavBar />

    <!-- CONTENT -->
    <section class="pt-8 pt-md-11 pb-8 pb-md-14">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md">

                    <!-- Heading -->
                    <h1 class="display-4 mb-2">
                        Allgemeine Geschäftsbedingungen
                    </h1>

                    <!-- Text -->
                    <p class="fs-lg text-gray-700 mb-md-0">
                        Stand: 01.01.2021
                    </p>

                </div>
                <div class="col-auto">

                    <!-- Buttons -->
                    <a href="#!" class="btn btn-primary-subtle">
                        Drucken
                    </a>

                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-12">

                    <!-- Divider -->
                    <hr class="my-6 my-md-8">

                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-12 col-md-8">

                    <!-- Text -->
                    <p class="fs-lg text-gray-800 mb-6 mb-md-8">
                        Mit den folgenden Informationen gibt AllDayCare Ihnen einen Überblick über die Verarbeitung
                        Ihrer personenbezogenen Daten durch unser Unternehmen. Weiter informieren wir Sie über Ihre
                        Rechte aus dem Datenschutzrecht.
                    </p>

                    <h3 class="mb-5">
                        1. Informationen über die Verarbeitung personenbezogener Daten
                    </h3>

                    <p class="text-gray-800">
                        Nachfolgend informieren wir Sie über die Erhebung von personenbezogenen Daten bei Besuch/Nutzung
                        unserer Webapplikation <strong>AllDayCare</strong> und unserer mobilen Applikation
                        <strong>AllDayCareMobile</strong>. Unter
                        personenbezogenen Daten sind nach Art. 4 Nr. 1 EU-Datenschutz-Grundverordnung (DSGVO) alle
                        Informationen zu verstehen, die sich auf eine identifizierte oder identifizierbare natürliche
                        Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder
                        indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
                        zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen
                        identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
                        psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser Person sind.
                    </p>


                    <h3 class="mb-5 mt-7">
                        2. Kontaktdaten unseres Datenschutzbeauftragten
                    </h3>

                    <p class="text-gray-800">
                        Unseren Datenschutzbeauftragten erreichen Sie per E-Mail unter datenschutz@alldaycare.de oder
                        postalisch unter Datenschutz - Dalbergstr. 9 - 68159 Mannheim.
                    </p>

                    <h3 class="mb-5 mt-7">
                        3. Datenkategorien, Zwecke und Rechtsgrundlagen der Verarbeitung Ihrer personenbezogenen Daten
                    </h3>

                    <p class="text-gray-800">
                        Wir verarbeiten folgende Ihrer personenbezogenen Daten:
                    </p>

                    <p>
                        Besuch und Nutzung unserer Internetseite:
                    </p>

                    <p class="text-gray-800">
                        Bei der reinen informatorischen Nutzung unserer Internetseite, verarbeiten wir lediglich die
                        personenbezogenen Daten, welche Ihr Browser an unseren Server übermittelt. Dabei handelt es sich
                        um Daten, die für unser Unternehmen technisch erforderlich sind, um Ihnen unsere Internetseite
                        anzuzeigen und die Sicherheit und Stabilität zu gewährleisten auf Rechtsgrundlage nach Art. 6
                        Abs. 1 Satz 1 lit. f DSGVO:
                    </p>

                    <!-- List -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            IP-Adresse
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Datum und Uhrzeit
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Browser
                        </p>

                    </div>

                    <!-- Text -->
                    <p class="text-gray-800">
                        Registrierung auf unserer Internetseite: Bei der Registrierung auf unserer Internetseite,
                        erheben wir die personenbezogenen Daten, welche wir zur Durchführung für Ihre Bestellung oder
                        einer anderen Leistung für Sie benötigen. Im Einzelnen sind dies folgende Daten:
                    </p>

                    <!-- List -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Anrede
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Vor- und Nachname
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Adressdaten
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Geburtsdatum
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Bestelldaten
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Zahlungsdaten
                        </p>

                    </div>

                    <h3 class="mb-5 mt-5">
                        3.1. Datenverarbeitung im Rahmen der Analyse und Behebung von Fehlern
                    </h3>

                    <p class="text-gray-800">
                        Im Rahmen von Fehleranalysen und -behebungen verarbeiten wir Fehlerberichte und Log-Dateien, die
                        – je nach Fehler im Einzelfall – die von Ihnen in AllDayCare gespeicherten Daten zum Inhalt
                        haben können. Hiervon können die Datenkategorien nach Ziffer 3c umfasst sein, aber auch solche
                        Daten von Dritten, die wir für Sie auf der Grundlage eines Auftragsverarbeitungsvertrags
                        verarbeiten.
                    </p>

                    <h3 class="mb-5 mt-5">
                        3.2. Erfassung von Nutzerinteraktionen
                    </h3>

                    <p class="text-gray-800">
                        Wir möchten Sie bei Ihrer Arbeit in AllDayCare durch passende Tipps und Gebrauchshinweise sowie
                        durch die zielgerichtete Bereitstellung von Support bestmöglich unterstützen. Aus diesem Grund
                        erfassen wir bestimmte Ereignisse in AllDayCare durch das Tracking von Nutzerinteraktionen
                        innerhalb der Software. In diesem Zusammenhang verarbeiten wir die folgenden Daten von Ihnen:
                    </p>

                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Name
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Softwareversion
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Datum und Uhrzeit
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            ggf. weitere innerhalb von AllDayCare erfasste statistische Daten
                        </p>

                    </div>

                    <h3 class="mb-5 mt-7">
                        4. Empfänger Ihrer personenbezogenen Daten
                    </h3>

                    <p class="text-gray-800">
                        Es werden keinerlei Daten an Dritte weitergeben.
                    </p>

                    <h3 class="mb-5 mt-7">
                        5. Übermittlung Ihrer Daten in ein Drittland oder an eine internationale Organisation
                    </h3>

                    <p class="text-gray-800">
                        Eine Datenübermittlung in Länder außerhalb der Europäischen Union bzw. des Europäischen
                        Wirtschaftsraum findet nicht statt.
                    </p>

                    <h3 class="mb-5 mt-7">
                        6. Dauer der Speicherung Ihrer personenbezogenen Daten
                    </h3>

                    <p class="text-gray-800">
                        Unser Unternehmen speichert Ihre Daten, solange es zur Erfüllung unserer vertraglichen und
                        gesetzlichen Pflichten nötig ist bzw. solange sie für die jeweiligen Zwecke erforderlich sind.
                        Sind Ihre Daten zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten nicht mehr
                        erforderlich oder ist der Zweck entfallen, werden diese gelöscht.
                    </p>

                    <h3 class="mb-5 mt-7">
                        7. Ihre Rechte
                    </h3>

                    <p>
                        Sie haben gegenüber unserem Unternehmen die folgenden Rechte im Hinblick auf Ihre
                        personenbezogenen Daten:
                    </p>


                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Recht auf Auskunft nach Art. 15 DSGVO
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Recht auf Berichtigung nach Art. 16 DSGVO oder Löschung nach Art. 17 DSGVO
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Recht auf Datenübertragbarkeit nach Art. 20 DSGVO
                        </p>

                    </div>
                    <div class="d-flex">

                        <!-- Badge -->
                        <span class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                            <i class="fe fe-check"></i>
                        </span>

                        <!-- Text -->
                        <p class="text-gray-800">
                            Recht auf Beschwerde bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                            personenbezogenen Daten nach Art. 77 DSGVO
                        </p>

                    </div>

                    <p class="text-gray-800">
                        Geltendmachung Ihrer Rechte: Wenden Sie sich zur Ausübung aller Ihrer zuvor genannten Rechte
                        bitte an die oben unter Ziffer 1b) angegebene Anschrift.
                    </p>

                    <h3 class="mb-5 mt-7">
                        8. Pflicht zur Bereitstellung von personenbezogenen Daten
                    </h3>

                    <p class="text-gray-800">
                        Im Allgemeinen können Sie unsere Internetseite aufrufen und einsehen, ohne personenbezogene
                        Daten anzugeben. Wenn Sie allerdings eine Registrierung vornehmen, müssen Sie bestimmte
                        personenbezogene Daten (siehe Punkt 3b) angeben. Ohne diese Daten werden wir nicht in der Lage
                        sein, Ihre Anfrage zu bearbeiten bzw. den Vertrag zu erfüllen.
                    </p>

                    <h3 class="mb-5 mt-7">
                        9. Automatisierte Entscheidungsfindung einschließlich Profiling
                    </h3>

                    <p class="text-gray-800">
                        Wir nutzen auf unserer Internetseite keine automatisierte Entscheidungsfindung einschließlich
                        Profiling.
                    </p>


                </div>
                <div class="col-12 col-md-4">

                    <NeedHelpComponent />

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
</template>

<script>
import NavBar from '@/components/Shared/NavBar.vue';
import NeedHelpComponent from '@/components/NeedHelpComponent.vue';

export default {
    components: {
        NavBar,
        NeedHelpComponent
    }
}
</script>