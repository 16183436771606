<template>
    <Navbar :isDark="false" />
    <!-- BREADCRUMB -->
    <nav class="bg-gray-200">
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <!-- Breadcrumb -->
                    <ol class="breadcrumb breadcrumb-scroll">
                        <li class="breadcrumb-item">
                            <a href="/help" class="text-gray-700">
                                Help Center
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" v-if="category != null">
                            {{ category.name }}
                        </li>
                    </ol>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </nav>

    <!-- CONTENT -->
    <section class="pt-6 pt-md-8 pb-8 mb-md-8">
        <div class="container">
            <div class="row" v-if="category != null">
                <div class="col-12">

                    <div class="row mb-6 mb-md-8">
                        <div class="col-auto">

                            <!-- Icon -->
                            <div class="icon-circle bg-primary text-white">
                                <i class="fe" :class="category.icon"></i>
                            </div>

                        </div>
                        <div class="col ms-n4">

                            <!-- Heading -->
                            <h2 class="fw-bold mb-0">
                                {{ category.name }}
                            </h2>

                            <!-- Text -->
                            <p class="fs-lg text-gray-700 mb-0" v-if="category != null">
                                {{ category.description }}
                            </p>

                        </div>
                    </div> <!-- / .row -->

                    <!-- Card -->
                    <div class="accordion shadow-light-lg mb-5 mb-md-6" :id="'topic' + topic.helpTopicId"
                        v-for="topic in category.topics" :key="topic.helpTopicId">
                        <div class="accordion-item">

                            <!-- Button -->
                            <div class="accordion-button" role="button">
                                <div class="me-auto">

                                    <!-- Heading -->
                                    <h4 class="fw-bold mb-0">
                                        {{ topic.topic }}
                                    </h4>

                                    <!-- Text -->
                                    <p class="fs-sm text-body-secondary mb-0">
                                        {{ topic.undertitle }}
                                    </p>

                                </div>

                                <!-- Badge -->
                                <span class="badge rounded-pill text-bg-success-subtle ms-4">
                                    <span class="h6 text-uppercase">
                                        {{ topic.questionsCount }} Antworten
                                    </span>
                                </span>

                            </div>

                        </div>
                        <div class="accordion-item" v-for="question in topic.questions" :key="question.id">
                            <!-- Button -->
                            <div class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                :data-bs-target="'#question' + question.helpQuestionId" aria-expanded="false"
                                aria-controls="helpOne">

                                <!-- Title -->
                                <span class="me-4" id="helpOneHeading">
                                    {{ question.question }}
                                </span>

                                <!-- Metadata -->
                                <div class="text-body-secondary ms-auto">

                                    <!-- Text -->
                                    <span class="fs-sm me-4 d-none d-md-inline">
                                        {{ question.latestUpdate }}
                                    </span>

                                </div>

                            </div>

                            <!-- Collapse -->
                            <div class="accordion-collapse collapse" :id="'question' + question.helpQuestionId"
                                aria-labelledby="helpOneHeading" :data-bs-parent="'#topic' + topic.helpTopicId">
                                <div class="accordion-body">

                                    <!-- Text -->
                                    <p class="text-gray-700">
                                        {{ question.answer }}
                                    </p>

                                    <!-- <div class="d-flex align-items-center">

                                        <div class="btn-group me-4">

                                            <input type="radio" class="btn-check" name="helpOneVote" id="helpOneDown"
                                                checked>
                                            <label class="btn btn-sm btn-white" for="helpOneDown">
                                                <i class="fe fe-thumbs-down"></i>
                                            </label>

                                            <input type="radio" class="btn-check" name="helpOneVote" id="helpOneUp">
                                            <label class="btn btn-sm btn-white" for="helpOneUp">
                                                <i class="fe fe-thumbs-up"></i>
                                            </label>

                                        </div>

                                        <span class="fs-sm text-body-secondary">
                                            Hat dir diese Antwort geholfen?
                                        </span>

                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>

    <!-- SHAPE -->
    <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x text-dark">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
            </svg>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'

import { getCategory } from '@/plugins/helpCenterService.js'

export default {
    name: 'HelpArticle',
    components: {
        Navbar,
    },
    props: {
        categoryId: Number
    },
    data() {
        return {
            category: null
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        async loadData() {
            this.category = await getCategory(this.categoryId);
        }
    }
};
</script>