<template>
    <Navbar :isDark="true" />
    <!-- WELCOME -->
    <!-- <section class="pt-10 pb-11 py-md-14 overlay overlay-black overlay-60 jarallax"> -->
    <section class="mt-n11 pt-12 pt-md-9 pb-11 pb-md-12 py-lg-14 bg-black bg-pattern-2 ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-7 text-center" data-aos="fade-up">

            <!-- Image (mobile) -->
            <!-- <img src="@/assets/img/illustrations/illustration-8.png" alt="..." class="img-fluid mb-6 d-lg-none"> -->

            <!-- Heading -->
            <h1 class="display-3 fw-bold">
              Leistungsstarke Features. <br>
              Bereit für jeden Einsatz.
            </h1>

            <!-- Text -->
            <p class="fs-lg text-body-secondary mb-6 mb-md-8">
              Optimiert für Ihre Bedürfnisse, flexibel für Ihre Anforderungen.
            </p>

            <!-- Button -->
            <a href="#!" class="btn btn-primary me-1 lift">
              Termin buchen <i class="fe fe-arrow-right ms-3"></i>
            </a>
            <a href="#!" class="btn btn-primary-subtle lift">
              Mehr erfahren
            </a>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>

    <!-- SHAPE -->
		<div class="position-relative">
			<div class="shape shape-bottom shape-fluid-x text-black">
				<svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
				</svg>
			</div>
		</div>

    <!-- SOLUTION -->
    <section class="py-3 pt-md-2 pb-md-12 bg-black">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">

            <!-- Preheading -->
            <h6 class="text-uppercase text-success fw-bold">
              Alles in einem
            </h6>

            <!-- Heading -->
            <h1 class="fw-bold text-white">
              Eine Rundum-Lösung für Ihre Alltagsbegleitung.
            </h1>

            <!-- Text -->
            <p class="fs-lg text-gray-500 mb-7 mb-md-9">
              Eine Komplettlösung für ihre Verwaltung.
            </p>

          </div>
        </div> <!-- / .row -->
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 mb-4">

            <!-- Heading -->
            <h4 class="fw-bold text-white mb-5">
              Stammdaten
            </h4>

            <!-- List -->
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Kundenanlage
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Mitarbeiterverwaltung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Standortverwaltung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300 mb-6 mb-lg-0">
                Leistungverwaltung
              </p>

            </div> <!-- / .row -->

          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-4">

            <!-- Heading -->
            <h4 class="fw-bold text-white mb-5">
              Abrechnung
            </h4>

            <!-- List -->
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Auftragsanlage
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Rechnungsverwaltung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Zahlungseingang
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300 mb-6 mb-lg-0">
                Mahnwesen
              </p>

            </div> <!-- / .row -->

          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-4">

            <!-- Heading -->
            <h4 class="fw-bold text-white mb-5">
              Erfassung
            </h4>

            <!-- List -->
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Belegerfassung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Digitale Belegerfassung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Sonderkonditionen
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300 mb-6 mb-lg-0">
                Einsatzverwaltung
              </p>

            </div> <!-- / .row -->

          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-4">

            <!-- Heading -->
            <h4 class="fw-bold text-white mb-5">
              Berichte
            </h4>

            <!-- List -->
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Umsatz
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Standortverteilung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300">
                Mitarbeiterauslastung
              </p>

            </div>
            <div class="d-flex">

              <!-- Badge -->
              <div class="badge badge-rounded-circle text-bg-success mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>

              <!-- Text -->
              <p class="text-gray-300 mb-0">
                Werbeverteilung
              </p>

            </div> <!-- / .row -->

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>

    <!-- SHAPE -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x text-black">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>      </div>
    </div>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'

export default {
  name: 'App',
  components: {
     Navbar,
  }
}
</script>