<template>
    <Navbar :isDark="true" />

    <section id="pricingSection" class="mt-n11 pt-12 pb-4 pt-md-14 pb-md-6 bg-black bg-pattern-2">
        <PricingView />
    </section>

    <!-- FAQ -->
    <section class="pt-5 bg-black">
        <div class="container py-8 py-md-11">
            <div class="row">
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Wie berechnen wir Ihren Beitrag?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-8">
                                Wir berechnen Ihnen nur das, was sie auch wirklich verwenden! Wir kalkulieren einen nur Kunden, wenn dieser im System aktiv ist.
                                Unser System stellt einen Kunden automatisch auf pausiert, sollte für diesen innerhalb der letzten zwei Monate keine Belege erfasst
                                oder Rechnungen fakturiert worden sein.
                            </p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Warum verwenden wir diese Abrechnungssystem?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-8">
                                Wir möchten verantwortunsvoll mit unseren Ressourcen umgehen. Jeder aktive Kunde bedeutet auch eine
                                Datentransfer und die damit entstehenden Kosten. Auch wenn es nur ein kleiner Teil ist, trägt dieser 
                                dazu bei, bewusster mit der Energie umzugehen!
                            </p>

                        </div>
                    </div>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'
import PricingView from '@/components/FrameworkSection/PricingView'

export default {
    components: {
        Navbar,
        PricingView,
    },
    data() {
        return {
            customerCount: 10,
            price: 5,
            formatter: new Intl.NumberFormat('de-de', { style: "currency", currency: 'EUR' })
        }
    },
    methods: {
        calculatePrice() {
            this.price = this.customerCount * 0.5
        }
    }
}

</script>