<template>
    <!-- Modal -->
    <div class="modal fade" id="confirmationRequestModal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Anfragebestätigung</h1>
                </div>
                <div class="modal-body">
                    <p>Vielen Dank für Deine Anfrage! Wir werden uns so schnell wie möglich mit einer Lösung bei dir melden!</p>
                    <p>Eine Bestätigung deiner Anfrage wurde an deine E-Mail-Adresse <strong>{{ helpRequest.email }}</strong>
                        gesendet. Bitte
                        überprüfe auch deinen Spam-Ordner, falls die E-Mail nicht in deinem Posteingang angezeigt wird.
                    </p>
                    <p>Bei weiteren Fragen kannst du uns jederzeit kontaktieren.</p>
                    <p>Mit freundlichen Grüßen,<br>AllDayCare</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" onclick="window.location.href='/help'">Zurück zum Hilfe-Zentrum</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import HelpRequest from '@/models/HelpRequest.js'

export default {
    props: {
        helpRequest: HelpRequest,
    },
    methods: {
        openModal() {
            const modal = new bootstrap.Modal(document.getElementById('confirmationRequestModal'));
            modal.show();
        },
    }
}
</script>