<template>
    <Navbar :isDark="true" />

    <section class="mt-n11 pt-12 pb-4 pt-md-14 pb-md-6 bg-black bg-pattern-2">
        <!-- WELCOME -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 text-center">

                    <!-- Headin -->
                    <h1 class="display-2 fw-bold text-white" data-aos="fade-up" data-aos-delay="50">
                        Alltagsbegleitung.<br>
                        <div class="d-none d-sm-block">Ihre Verwaltung digitalisiert.</div>
                        <div class="d-block d-sm-none">Digitalisiert.</div>
                    </h1>

                    <!-- Text -->
                    <p class="lead text-gray-500 mb-6 mx-auto" data-aos="fade-up" data-aos-delay="100"
                        style="max-width: 500px;">
                        Die intuitive und sichere Softwarelösung, die sich den spezifischen Anforderungen der
                        Angebote zur Unterstützung im Alltag anpasst.
                    </p>

                    <!-- Button -->
                    <p class="mb-7 mb-md-9" data-aos="fade-up" data-aos-delay="150">
                        <a class="btn btn-primary shadow lift" href="contact">
                            Jetzt Termin buchen <i class="fe fe-arrow-right ms-3"></i>
                        </a>
                    </p>

                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-12" data-aos="fade-up" data-aos-delay="200">

                    <!-- Media -->
                    <div
                        class="position-relative p-sm-0 p-md-7 mb-7 mb-md-9 rounded-4 shadow-multicolor overflow-hidden">

                        <!-- Background -->
                        <div class="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor"></div>

                        <!-- Image -->
                        <img class="position-relative img-fluid rounded shadow-lg"
                            src="@/assets/img/screenshots/desktop/Screenshot Dashboard_2.png" alt="..." />

                    </div>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>

    <!-- FEATURES -->
    <section class="bg-black pb-8 pt-4 pb-md-11 pt-md-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-7 text-center">

                    <!-- Heading -->
                    <h2 class="display-3 fw-bold text-white">
                        All-in-One Lösung für Ihren Alltagsbetrieb
                    </h2>

                    <!-- Text -->
                    <p class="lead text-body-secondary mb-9">
                        Entdecken Sie die einfache Art, Ihr Unternehmen zu managen. Mit AllDayCare erhalten Sie eine
                        umfassende, benutzerfreundliche Software, die von Kundenmanagement bis hin zu effizienten
                        Abrechnungssystemen alles bietet.
                    </p>

                </div>
            </div> <!-- / .row -->
            <div class="row mt-n7">
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 rounded-3" style="background-color: #064720;">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid"
                                src="@/assets/img/illustrations/Office management-amico_background.svg" alt="..."
                                style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Effizientes Management
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Verwalten Sie Kunden und Mitarbeiter nahtlos
                            </p>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 bg-dark rounded-3">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid"
                                src="@/assets/img/illustrations/Progress overview-pana_background.svg" alt="..."
                                style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Echtzeit-Übersichten
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Behalten Sie Umsatz und Ressourcennutzung im Blick
                            </p>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 bg-dark rounded-3">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid" src="@/assets/img/illustrations/Location search-amico_background.svg"
                                alt="..." style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Standortvisualisierung
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Alle Kunden und Mitarbeiter auf einer Karte
                            </p>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 bg-dark rounded-3">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid" src="@/assets/img/illustrations/Printing invoices-amico_background.svg"
                                alt="..." style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Automatisierte Abrechnung
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Erfassen und verwalten Sie Belege effizient
                            </p>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 bg-dark rounded-3">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid" src="@/assets/img/illustrations/Product iteration-rafiki_background.svg"
                                alt="..." style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Flexible Leistungsgestaltung
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Passen Sie Dienstleistungen individuell an
                            </p>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-6 col-lg-4 mt-7">

                    <!-- Card -->
                    <div class="card h-100 bg-dark rounded-3">
                        <div class="card-body p-5 pb-0 my-auto">
                            <img class="img-fluid" src="@/assets/img/illustrations/Mobile-bro_background.svg" alt="..."
                                style="max-width: 200px;">
                        </div>
                        <div class="card-body p-5">

                            <!-- Heading -->
                            <h3 class="fw-bold text-white">
                                Mobil und vernetzt
                            </h3>

                            <!-- Text -->
                            <p class="text-body-secondary mb-0">
                                Erfassung und Reporting direkt beim Kunden
                            </p>

                        </div>
                    </div>
                </div>
                <div class="mt-2 text-center">
                    <a href="https://storyset.com/technology">Technology illustrations by Storyset</a>
                </div>
            </div> <!-- / .row -->
        </div>
    </section>

    <!-- ABOUT -->
    <section class="pt-8 pt-md-11 bg-black">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-md-6 col-lg-5">

                    <!-- Heading -->
                    <h2 class="display-3 fw-bold text-white mb-6 mb-md-8">
                        Digitalisierte Leistungsnachweise
                    </h2>

                    <!-- Text -->
                    <p class="text-body-secondary lead mb-6">
                        <span class="text-white fw-bold">Zuverlässig.</span> Unsere App ist immer einsatzbereit und
                        sorgt
                        dafür, dass Ihre Arbeit reibungslos weiterläuft.
                    </p>

                    <!-- Text -->
                    <p class="text-body-secondary lead mb-7 mb-md-0">
                        <span class="text-white fw-bold">Intuitiv.</span> Unsere App ist so gestaltet, dass jeder,
                        unabhängig von seiner technischen Erfahrung, sie problemlos nutzen kann.
                    </p>

                </div>
                <div class="col-12 col-md-6 text-center text-md-start">

                    <!-- Media -->
                    <div class="pt-7 pb-2 px-3 px-md-3 px-lg-9 rounded-4"
                        style="max-width: 800px; background-image: linear-gradient(45deg,#04431d 0%,#12d15e 100%);">

                        <div id="carouselExampleFade" class="carousel slide carousel-fade">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="d-flex justify-content-center" style="height: 650px;">
                                        <img max-height="650" max-width="300"
                                            src="@/assets/img/screenshots/mobile/LoginScreen.svg" alt="...">
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="d-flex justify-content-center" style="height: 650px;">
                                        <img src="@/assets/img/screenshots/mobile/ClientOverviewScreen.svg"
                                            max-height="650" max-width="300" alt="...">
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="d-flex justify-content-center" style="height: 650px;">
                                        <img src="@/assets/img/screenshots/mobile/ReceiptScreen.svg" max-height="650"
                                            max-width="300" alt="...">
                                    </div>

                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> <!-- / .row -->
        </div>
    </section>

    <!-- ABOUT -->
    <section class="pt-8 pt-md-11 bg-black">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-md-6 col-lg-5 order-md-1">

                    <!-- Heading -->
                    <h2 class="display-4 fw-bold text-white mb-6 mb-md-8">
                        Vereinfachte Abrechnung
                    </h2>

                    <!-- Text -->
                    <p class="text-body-secondary lead mb-6">
                        <span class="text-white fw-bold">Aufträge.</span> Bündeln Sie Abrechnungen für einen Kunden
                        und erstellen Sie problemlos aufeinander folgende Verhinderungspflege-, Pflegekassen- und
                        Privatrechnungen.
                    </p>

                    <!-- Text -->
                    <p class="text-body-secondary lead mb-7 mb-md-0">
                        <span class="text-white fw-bold">Dokumente.</span> Passen Sie Dokumente ganz einfach
                        nach Ihren Wünschen an.
                    </p>

                </div>
                <div class="col-12 col-md-6 col-lg-6 order-md-0">

                    <!-- Positioner -->
                    <div class="position-relative">

                        <!-- Media -->
                        <div class="position-relative vw-md-50 p-7 p-md-9 ps-0 overflow-hidden float-end"
                            style="max-width: 800px;">

                            <!-- Background -->
                            <div class="position-absolute top-0 end-0 bottom-0 start-0 rounded-4"
                                style="background-image: linear-gradient(45deg, #12d15e 0%,#04431d 100%);">
                            </div>

                            <!-- Image -->
                            <img class="position-relative w-100 w-md-120 float-end rounded"
                                src="@/assets/img/screenshots/desktop/Invoicing.png" alt="...">

                        </div>
                    </div>
                </div>
            </div> <!-- / .row -->
        </div>
    </section>

    <section id="pricingSection" class="pt-9 pt-md-12 bg-black">
        <PricingView />
    </section>

    <!-- FAQ -->
    <section class="pt-5 bg-black">
        <div class="container pt-8 pt-md-11">
            <div class="row">
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Wie kann AllDayCare meinen Alltag vereinfachen?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-8">
                                AllDayCare vereinfacht Ihren Alltag, indem es die Verwaltung von Kunden, Mitarbeitern
                                und Rechnungen zentralisiert und automatisiert.
                                Dadurch sparen Sie Zeit und reduzieren den administrativen Aufwand, sodass Sie sich auf
                                Ihre Kernaufgaben konzentrieren können.
                            </p>

                        </div>

                    </div>

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Was unterscheidet AllDayCare von anderen Anbietern?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-0">
                                AllDayCare arbeitet eng mit seinen Partnern zusammen, um individuelle Lösungen zu
                                erarbeiten und Prozesse optimal abzubilden.
                                Diese Lösungen werden unter den Partnern geteilt, sodass jeder von den besten Praktiken
                                und Innovationen profitieren kann.
                            </p>

                        </div>

                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Wann kann ich beginnen?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-8">
                                Sie können nach einem kurzen persönlichen Termin und der Einrichtung Ihres Unternehmens
                                direkt mit AllDayCare beginnen.
                                Unser Team unterstützt Sie bei der schnellen und unkomplizierten Erstellung, sodass Sie
                                sofort loslegen können.
                            </p>

                        </div>

                    </div>

                    <!-- Item -->
                    <div class="d-flex">

                        <!-- Badge -->
                        <div class="badge badge-lg badge-rounded-circle text-bg-success">
                            <span>?</span>
                        </div>

                        <div class="ms-5">

                            <!-- Heading -->
                            <h4 class="text-white">
                                Welchen langfristigen Nutzen bietet AllDayCare?
                            </h4>

                            <!-- Text -->
                            <p class="text-body-secondary mb-6 mb-md-0">
                                Mit AllDayCare haben Sie einen zuverlässigen Partner für die Digitalisierung Ihres
                                Unternehmens an Ihrer Seite.
                                Alle Informationen und Prozesse sind zentral über eine Plattform verfügbar, was
                                langfristig Zeit spart und die Effizienz steigert.
                            </p>

                        </div>

                    </div>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>

    <!-- CTA -->
    <section class="py-10 py-md-14 bg-black">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 text-center">

                    <!-- Pretitle -->
                    <h6 class="text-uppercase text-success mb-6">
                        Digital in die Zukunft
                    </h6>

                    <!-- Heading -->
                    <h1 class="display-1 fw-bold text-white">
                        Persönlicher Kontakt ist uns wichtig.
                    </h1>

                    <!-- Text -->
                    <p class="fs-lg text-gray-500 mb-6 mb-md-8">
                        Planen Sie jetzt einen Termin für ein unverbindliches Erstgespräch und lassen Sie sich von uns
                        überzeugen.
                    </p>

                    <!-- Button -->
                    <a href="contact" class="btn btn-primary lift">
                        Jetzt Termin buchen <i class="fe fe-arrow-right ms-2"></i>
                    </a>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'
import PricingView from '@/components/FrameworkSection/PricingView.vue';

export default {
    name: "HomePage",
    components: {
        Navbar,
        PricingView
    },
    methods: {
        scrollToPrice() {
            const pricingSection = document.getElementById("pricingSection")

            this.$smoothScroll({
                scrollTo: pricingSection,
                hash: '#sampleHash'
            })
        },
    }
}
</script>

<style>
/* .bg-black {
  background-color: #022812!important;
} */
</style>