<template>
    <Navbar :isDark="false" />

    <!-- CONTENT -->
    <section class="pt-8 pt-md-11 pb-8 pb-md-14">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md">

                    <!-- Heading -->
                    <h1 class="display-4 mb-2">
                        Impressum
                    </h1>

                    <!-- Text -->
                    <p class="fs-lg text-gray-700 mb-md-0">
                        Stand: 01.01.2021
                    </p>

                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-12">

                    <!-- Divider -->
                    <hr class="my-6 my-md-8">

                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-12 col-md-8">

                    <h6 class="fw-bold text-uppercase text-gray-700 mb-2">Unternehmen</h6>
                    <p class="fs-lg text-gray-900 mb-6 mb-md-8">
                        AllDayCare<br>
                        Ingo Weber<br>
                        Dalbergstr. 9<br>
                        68159 Mannheim
                    </p>

                    <h6 class="fw-bold text-uppercase text-gray-700 mb-2">Kontaktinformationen</h6>

                    <p class="fs-lg text-gray-900 mb-6 mb-md-8">
                        Telefon: +4917665883595<br>
                        E-Mail: info@alldaycare.de<br>
                        38449/34208
                    </p>

                    <h6 class="fw-bold text-uppercase text-gray-700 mb-2">Vertretungsberechtigter</h6>

                    <p class="fs-lg text-gray-900 mb-6 mb-md-8">
                        Ingo Weber, Geschäftsführung
                    </p>

                    <p class="fs-lg text-gray-900 mb-6 mb-md-8">
                        Dieses Impressum gilt für alle Angebote unter der Domain www.alldaycare.de inklusive aller Subdomains (Unterseiten).
                    </p>
                </div>
                <div class="col-12 col-md-4 d-print-none">

                    <NeedHelpComponent />
                    
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'
import NeedHelpComponent from '@/components/NeedHelpComponent.vue';

export default {
    components: {
        Navbar,
        NeedHelpComponent
    }
}
</script>