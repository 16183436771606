import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCATV5rOqYau9NfFWQkeGb43vc4PTNTLLI",
    authDomain: "alldaycare-1.firebaseapp.com",
    projectId: "alldaycare-1",
    storageBucket: "alldaycare-1.appspot.com",
    messagingSenderId: "357694327027",
    appId: "1:357694327027:web:3574c9a3872154631f8f86",
    measurementId: "G-6VQXTWVDVY"
})

//Database
export const db = getFirestore(firebaseApp)