<template>
	<Navbar :isDark="false" />

	<div class="bg-light">


		<!-- WELCOME -->
		<section data-jarallax data-speed=".8" class="pt-10 pb-11 py-md-14 overlay overlay-black overlay-60 jarallax"
			:style="{ backgroundImage: `url(${require('@/assets/img/covers/help-center.jpg')})` }">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 text-center">

						<!-- Heading -->
						<h1 class="display-2 fw-bold text-white">
							Wie können wir helfen?
						</h1>

						<!-- Text -->
						<p class="lead text-white text-opacity-75 mb-0">
							Wir sind hier, um Dir zu helfen, AllDayCare optimal zu nutzen. Lass uns zuerst prüfen, ob
							wir
							eine Lösung in unserer Dokumentation finden können.
						</p>

					</div>
				</div> <!-- / .row -->
			</div> <!-- / .container -->
		</section>

		<!-- SHAPE -->
		<div class="position-relative">
			<div class="shape shape-bottom shape-fluid-x text-light">
				<svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
				</svg>
			</div>
		</div>

		<!-- CATEGORIES -->
		<section>
			<div class="container">
				<div class="row">
					<div class="col-12">

						<!-- Form -->
						<form class="rounded shadow mt-n6 mb-4">
							<div class="input-group input-group-lg">

								<!-- Text -->
								<span class="input-group-text border-0 pe-1">
									<i class="fe fe-search"></i>
								</span>

								<!-- Input -->
								<input class="form-control border-0 px-1" type="text"
									aria-label="Suche nach einer Lösung..." placeholder="Suche nach einer Lösung...">

								<!-- Text -->
								<span class="input-group-text border-0 py-0 ps-1 pe-3">
									<button class="btn btn-sm btn-primary" type="submit">
										Suchen
									</button>
								</span>

							</div>
						</form>

						<div class="mb-6 mb-md-8">

						</div>
						<!-- <div class="row align-items-center mb-6 mb-md-8">
							<div class="col-auto">

								<h6 class="fw-bold text-uppercase text-body-secondary mb-0">
									Häufige Suchen:
								</h6>

							</div>
							<div class="col ms-n5">

								<p class="fs-sm text-body-secondary mb-0">
									Redownload my theme, Request an invoice, Update my .zip
								</p>

							</div>
						</div> -->

					</div>
				</div> <!-- / .row -->
				<div class="row">
					<div class="col-12 col-md-6 col-lg-4" v-for="category in helpCategories" :key="category.id">
						<!-- Card -->
						<div class="card card-border border-primary shadow-lg mb-6 mb-md-8 lift lift-lg position-relative"
							style="cursor: pointer;" @click="goToArticle(category.helpCategoryId)">
							<div class="card-body text-center">

								<!-- Icon -->
								<div class="icon-circle text-white mb-5" :class="'bg-' + category.color">
									<i class="fe" :class="category.icon"></i>
								</div>

								<!-- Heading -->
								<h4 class="fw-bold">
									{{ category.name }}
								</h4>

								<!-- Text -->
								<p class="text-gray-700 mb-5">
									{{ category.description }}
								</p>

								<!-- Badge -->
								<button class="badge rounded-pill text-bg-dark-subtle">
									<span class="h6 text-uppercase">
										{{ category.entriesCount }} Einträge
									</span>
								</button>

							</div>
						</div>
					</div>
				</div> <!-- / .row -->
			</div>
		</section>

		<CreateHelpRequest />

		<!-- SHAPE -->
		<div class="position-relative">
			<div class="shape shape-bottom shape-fluid-x text-dark">
				<svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Shared/NavBar.vue'
import CreateHelpRequest from '@/components/CreateHelpRequest.vue'

import { getCategories } from '@/plugins/helpCenterService.js'

export default {
	components: {
		Navbar,
		CreateHelpRequest,
	},
	data() {
		return {
			helpCategories: [],
		}
	},
	mounted(){
        this.loadData()
    },
	methods: {
		async loadData(){
			this.helpCategories = await getCategories();
		},
		goToArticle(categoryId) {
			this.$router.push(`/help/${categoryId}`)
		}
	}
}

</script>