import { createRouter, createWebHistory } from 'vue-router'


import HomePage from '@/views/HomePage.vue'
import TermsPage from '@/views/TermsPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import FunctionOverviewPage from '@/views/FunctionOverviewPage.vue'
import PricingPage from '@/views/PricingPage.vue'
import HelpPage from '@/views/HelpPage.vue'
import HelpArticle from '@/views/HelpArticle.vue'
import ImprintPage from '@/views/ImprintPage.vue'

const routes = [
	{ path: '/', component: HomePage, name: 'home' },
	{ path: '/terms', component: TermsPage, name: 'terms' },
	{ path: '/imprint', component: ImprintPage, name: 'imprint'},
	{ path: '/contact', component: ContactPage, name: 'contact' },
	{ path: '/functions', component: FunctionOverviewPage, name: 'functions' },
	{ path: '/pricing', component: PricingPage, name: 'pricing' },
	{ path: '/help', component: HelpPage, name: 'help' },
	{ path: '/help/:categoryId', component: HelpArticle, name: 'article', props: true },
]

// Router-Konfiguration
export const router = createRouter({
	history: createWebHistory(),
	routes: routes,
	scrollBehavior (){
		return { top: 0 }
	}
})