import { Expose } from 'class-transformer';

class DateSuggestion {
    @Expose({ name: 'dateSuggestionId' })
    dateSuggestionId;

    @Expose({ name: 'begin' })
    begin;

    @Expose({ name: 'end' })
    end;

    getDate(){
        const beginDate = new Date(this.begin);

        let day = beginDate.getDate();
        let month = beginDate.getMonth();
        let year = beginDate.getFullYear();

        return new Date(year, month, day)
    }

    getDateString() {
        const beginDate = new Date(this.begin);

        let day = beginDate.getDate();
        let month = beginDate.getMonth();
        let year = beginDate.getFullYear();

        if (day < 10){
            day = "0" + day;
        }

        if (month < 10){
            month = "0" + month
        }

        return year + '-' + month + '-' + day
    }

    getTimeString() {
        const beginDate = new Date(this.begin);
        const endDate = new Date(this.end);

        let beginHour = beginDate.getHours();
        let beginMinutes = beginDate.getMinutes();
        let endHour = endDate.getHours();
        let endMinutes = endDate.getMinutes();

        if (beginHour < 10){
            beginHour = "0" + beginHour;
        }
        if (beginMinutes < 10){
            beginMinutes = "0" + beginMinutes;
        }
        if (endHour < 10){
            endHour = "0" + endHour;
        }
        if (endMinutes < 10){
            endMinutes = "0" + endMinutes;
        }

        return beginHour + ":" + beginMinutes + " - " + endHour + ":" + endMinutes
    }
}

export default DateSuggestion;