import { Expose } from 'class-transformer';

class HelpRequest {
    @Expose({ name: 'helpRequestId' })
    helpRequestId;

    @Expose({ name: 'name' })
    name;

    @Expose({ name: 'email' })
    email;

    @Expose({ name: 'request' })
    request;

    @Expose({ name: 'createdOn' })
    createdOn;
}

export default HelpRequest;