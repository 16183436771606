import { Expose } from 'class-transformer';

class Company {
    @Expose({ name: 'companyId' })
    companyId;

    @Expose({ name: 'name' })
    name;

    @Expose({ name: 'size' })
    size;

    @Expose({ name: 'city' })
    city;

    @Expose({ name: 'state' })
    state;

    @Expose({ name: 'websiteUrl' })
    websiteUrl;
}

export default Company;