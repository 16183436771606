<template>
    <!-- PRICING -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="text-white">
                    Faire Preisgestaltung für alle.
                </h1>

                <!-- Text -->
                <p class="lead text-gray-700">
                    Wir glauben, dass jedes Unternehmen Zugang zu hochwertigen digitalen Lösungen haben sollte.
                    Ihre Digitalisierung zu fairen Preisen.
                </p>
            </div>
        </div> <!-- / .row -->
        <div class="row align-items-center gx-0 mt-7 mt-md-9">
            <div class="col-12 col-md-6 ">

                <!-- Card -->
                <div class="shadow-multicolor" data-aos="fade-up">
                    <div class="card rounded-lg shadow-lg mb-6 mb-md-0" style="z-index: 1;">

                        <!-- Body -->
                        <div class="card-body py-6 py-md-8">
                            <div class="row justify-content-center">
                                <div class="col-12 col-xl-9">

                                    <!-- Badge -->
                                    <div class="text-center mb-5">
                                        <span class="badge rounded-pill text-bg-primary-subtle">
                                            <span class="h6 fw-bold text-uppercase">Standard</span>
                                        </span>
                                    </div>

                                    <!-- Price -->
                                    <div class="d-flex justify-content-center">
                                        <span class="price display-2 mb-0" data-annual="29" data-monthly="49">0,50 €</span>
                                        <span class="h2 align-self-end mb-1">/mo</span>
                                    </div>

                                    <!-- Text -->
                                    <p class="text-center text-body-secondary mb-6 mb-md-8">
                                        pro Kunde
                                    </p>

                                    <!-- Features -->
                                    <div class="d-flex">

                                        <!-- Check -->
                                        <div class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                                            <i class="fe fe-check"></i>
                                        </div>

                                        <!-- Text -->
                                        <p>
                                            Stammdatenverwaltung
                                        </p>

                                    </div>
                                    <div class="d-flex">

                                        <!-- Check -->
                                        <div class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                                            <i class="fe fe-check"></i>
                                        </div>

                                        <!-- Text -->
                                        <p>
                                            manuelle und mobile Belegerfassung
                                        </p>

                                    </div>
                                    <div class="d-flex">

                                        <!-- Check -->
                                        <div class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                                            <i class="fe fe-check"></i>
                                        </div>

                                        <!-- Text -->
                                        <p>
                                            Rechnungserstellung
                                        </p>

                                    </div>
                                    <div class="d-flex">

                                        <!-- Check -->
                                        <div class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                                            <i class="fe fe-check"></i>
                                        </div>

                                        <!-- Text -->
                                        <p>
                                            Echtzeitübersicht
                                        </p>

                                    </div>
                                    <div class="d-flex">

                                        <!-- Check -->
                                        <div class="badge badge-rounded-circle text-bg-success-subtle mt-1 me-4">
                                            <i class="fe fe-check"></i>
                                        </div>

                                        <!-- Text -->
                                        <p class="mb-0">
                                            Auswertungen
                                        </p>

                                    </div>

                                </div>
                            </div> <!-- / .row -->
                        </div>

                        <!-- Button -->
                        <a href="contact" class="card-btn btn w-100 btn-lg btn-primary">
                            Jetzt starten
                        </a>
                    </div>
                </div>


            </div>
            <div class="col-12 col-md-6 ms-md-n3 ">

                <!-- Card -->
                <div class="shadow-multicolor" data-aos="fade-up" data-aos-delay="200">
                    <div class="card rounded-lg shadow-lg">


                        <!-- Body -->
                        <div class="card-body py-6 py-md-8">
                            <div class="row justify-content-center">
                                <div class="col-12 col-xl-10">

                                    <!-- Badge -->
                                    <p class="text-center mb-8 mb-md-11">
                                        <span class="badge rounded-pill text-bg-primary-subtle">
                                            <span class="h6 fw-bold text-uppercase">Enterprise</span>
                                        </span>
                                    </p>

                                    <!-- Text -->
                                    <p class="lead text-center text-body-secondary mb-0 mb-md-10">
                                        Wir bieten variable Preise für größere Organisationen mit mehr als 300
                                        Kunden
                                        an.
                                        Nehmen Sie gerne Kontakt mit uns auf, und wir finden gemeinsam eine passende
                                        Lösung.
                                    </p>

                                </div>
                            </div> <!-- / .row -->
                        </div>

                        <!-- Button -->
                        <a href="contact" class="card-btn btn w-100 btn-lg btn-light bg-gray-300 text-gray-700">
                            Kontaktiere uns
                        </a>
                    </div>
                </div>


            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</template>