import HelpCategory from "@/models/HelpCategory";

import axios from 'axios'
import { plainToClass } from 'class-transformer';

const apiUri = process.env.VUE_APP_API_URL;

export async function getCategories() {
    const response = await axios.get(`${apiUri}/api/HelpCategories`);
    const categories = plainToClass(HelpCategory, response.data);
    return categories
}

export async function searchCategories(){
    //TODO
}

export async function getCategory(categoryName) {
    const response = await axios.get(`${apiUri}/api/HelpCategories/` + categoryName);
    const category = plainToClass(HelpCategory, response.data);
    return category
}

export async function sendHelpRequest(helpRequest) {
    try {
        const requestBody = JSON.stringify(helpRequest);

        const response = await axios.put(`${apiUri}/api/HelpCategories/Request`, requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        console.log('Response:', response.data);
    } catch (error) {
        console.error('There was an error!', error);
    }
}