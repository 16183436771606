<template>
	<!-- NAVBAR -->
	<nav class="navbar navbar-expand-lg" :class="{'navbar-dark': isDark, 'navbar-light': !isDark}">
		<div class="container-fluid">

			<!-- Brand -->
			<a class="navbar-brand" href="/">
				<img src="@/assets/img/logos/AllDayCareText.svg" class="navbar-brand-img" alt="...">
			</a>

			<!-- Toggler -->
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
				aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<!-- Collapse -->
			<div class="collapse navbar-collapse" id="navbarCollapse">

				<!-- Toggler -->
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
					aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<i class="fe fe-x"></i>
				</button>

				<!-- Navigation -->
				<ul class="navbar-nav ms-auto">
					<li class="nav-item">
						<a class="nav-link" href="/functions">
							Funktionen
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/pricing">
							Preise
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/help">
							Hilfe
						</a>
					</li>
				</ul>

				<!-- Button -->
				<a class="navbar-btn btn btn-sm btn-primary lift ms-auto" href="/contact">
					Termin buchen
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
export default{
    props: {
        isDark: {
            type: Boolean,
            required: true
        }
    }
}
</script>